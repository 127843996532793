import React from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AddIcon from '@material-ui/icons/Add';

// import * as Utils from '../utils';

function Teams(props) {
  const { user, teams, metaData } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const teamList = metaData && metaData.length > 0 ? metaData[0].teamList : [];

  const addTeamToUser = (idx) => {
    if (!user) return;
    const t = teams ? teams : [];
    const found = t.find((y) => y === teamList[idx].id);
    if (!found) {
      t.push(teamList[idx].id);
      window.firestore.collection('roleMap').doc(user).set({ teams: t }, { merge: true })
        .then(ret => {})
        .catch((err) => { enqueueSnackbar('err', { variant: 'error' }); });
    }
  };

  const removeTeamFromUser = (idx) => {
    if (!user) return;
    if (idx >= teams.length) return;
    const t = [...teams];
    t.splice(idx, 1);
    window.firestore.collection('roleMap').doc(user).set({ teams: t }, { merge: true })
      .then((ret) => {})
      .catch((err) => { enqueueSnackbar(err, { variant: 'error' }); });
  };

  return (
    <div>
      {teams && teams.map((x, idx) => {
        let t = teamList ? teamList.find((y) => y.id === x) : { name: '', color: 'white' };
        t = t ? t : { name: '', color: 'white' };
        return (
          <Chip
            key={x}
            label={t.name}
            style={{ background: t.color, margin: 2 }}
            variant="outlined"
            onDelete={() => {
              removeTeamFromUser(idx);
            }}
          >
          </Chip>
        );
      })}
      <IconButton
        aria-label="add-team"
        aria-haspopup="true"
        color="primary"
        size="small"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <AddIcon/>
      </IconButton>
      <Menu
        id="team-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {teamList.map((x, idx) => (
          <MenuItem
            key={x.color}
            style={{ background: '#666666', color: x.color }}
            onClick={() => {
              addTeamToUser(idx); setAnchorEl(null);
            }}
          >
            {`Team ${x.name}`}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const mapStateToProps = (state) => ({
  metaData: state.firestore.metaData,
});

export default connect(mapStateToProps)(Teams);
