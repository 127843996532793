/* eslint-disable no-console, react/jsx-props-no-spreading, react/prop-types,
   no-param-reassign, no-shadow, no-use-before-define */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import Won from './Won';

function OverallStats(props) {
  const { profiles } = props;
  const [leads, setLeads] = useState([]);

  useEffect(() => {
    // d is last April
    const d = new Date();
    if (d.getMonth() <= 2) {
      d.setFullYear(d.getFullYear() - 1);
    }
    d.setMonth(3);
    d.setDate(1);
    d.setHours(0);
    d.setMinutes(0);
    const da = Math.floor(d);
    setLeads(profiles
      .filter((x) => (x.createdDate > da || x.deployDate > da)));
  }, [profiles]);

  return (
    <Container maxWidth="lg" style={{ padding: 4 }}>
      <Grid container spacing={3} direction="row">
        <Grid item sm={12}>
          <Won leads={leads} />
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  profiles: state.firestore.profiles,
  roles: state.firestore.roles,
});

export default connect(mapStateToProps)(OverallStats);
