/* eslint-disable no-param-reassign */
import * as Utils from './utils';

const ProfileStates = [
  {
    title: 'all',
    color: '#5f5fd3',
    entry: {
      capture: [],
    },
    next: [],
  },
  {
    title: 'prospecting',
    color: '#db3ee8',
    icon: '',
    entry: {
      capture: [],
    },
    next: [
      'qualified',
      'unqualified',
    ],
  },
  {
    title: 'qualified',
    color: '#e8da3e',
    icon: '',
    entry: {
      onEntry: () => {},
      onSubmit: () => {},
      capture: [
        { field: 'estimatedStrength', label: 'Estimated Strength', type: 'number' },
        {
          field: 'estimatedRate',
          label: 'Monthly Billing (Lakhs)',
          type: 'number',
          validate: (value) => (value < 0 || value > 100 ? ({ error: 'Enter monthly billing in lakhs. Example: 1.2' }) : null),
        },
      ],
    },
    next: ['proposal', 'unqualified'],
  },
  {
    title: 'proposal',
    color: '#e7e83e',
    icon: '',
    entry: {
      capture: [],
    },
    next: ['meeting', 'unqualified', 'lost'],
  },
  {
    title: 'meeting',
    color: '#c0e83e',
    icon: '',
    entry: {
      capture: [
        { field: 'meetingDate', label: 'Meeting date', type: 'date' },
      ],
    },
    next: ['negotiation', 'unqualified', 'won', 'lost'],
  },
  {
    title: 'negotiation',
    color: '#8ce83e',
    icon: '',
    entry: {
      capture: [],
    },
    next: ['unqualified', 'won', 'lost'],
  },
  {
    title: 'won',
    color: '#3ee843',
    icon: '',
    entry: {
      capture: [
        { field: 'contractDate', label: 'Contract confirmation date', type: 'date' },
        { field: 'deployDate', label: 'Deployment date', type: 'date' },
        { field: 'approvedStrength', label: 'Approved strength', type: 'number' },
      ],
    },
    next: ['lost'],
  },
  {
    title: 'unqualified',
    color: '#00d4aa',
    icon: '',
    entry: {
      capture: [
        { field: 'unqualifiedReason', label: 'Reason for unqualifying', type: 'text' },
      ],
    },
    next: [],
  },
  {
    title: 'lost',
    color: '#00ccff',
    icon: '',
    entry: {
      capture: [
        { field: 'lostReason', label: 'Reason for losing', type: 'text' },
      ],
    },
    next: [],
  },
];

const updateProfile = (profileId, record) => window
  .firestore
  .collection('data')
  .doc(global.role.orgId)
  .collection('profiles')
  .doc(profileId)
  .set(record, { merge: true });

const appendHistory = (profileId, message, prevState, state) => {
  const time = Utils.timestamp();
  window
    .firestore
    .collection('data')
    .doc(global.role.orgId)
    .collection('history')
    .doc(profileId)
    .set({
      [time]: {
        time,
        authorId: global.role.appUid,
        authorOrg: global.role.orgId,
        message,
        prevState,
        state,
      },
    }, { merge: true });
};

// For each type of captured field, generate a comment
const commentMap = {
  text: (capture, val) => (`${capture.label}: ${val[capture.field]}`),
  number: (capture, val) => (`${capture.label}: ${val[capture.field]}`),
  date: (capture, val) => (`${capture.label}: ${Utils.printDate(new Date(val[capture.field]))}`),
  autocomplete: (capture, val) => (`${capture.label}: ${val[capture.field]}`),
};
// For each type of captured field, save field in record
const recordMap = {
  text: (capture, val, record) => { record[capture.field] = val[capture.field]; },
  number: (capture, val, record) => { record[capture.field] = val[capture.field]; },
  date: (capture, val, record) => { record[capture.field] = val[capture.field]; },
  autocomplete: (capture, val, record) => { record[capture.field] = val[capture.field]; },
};

const getProfileStates = () => {
  const ps = [...ProfileStates];
  if (!global.Store) {
    return ps;
  }
  // const store = global.Store.get();
  // const state = store.getState();

  ps.forEach((slot) => {
    // eslint-disable-next-line
    slot.entry.onSubmit = (val, row) => {
      // Init rec
      const rec = {
        state: slot.title,
        prevState: row.state,
      };
      // Generate comment by iterating over each capture entry
      const cmt = slot
        .entry
        .capture.map((x) => commentMap[x.type](x, val))
        .reduce(
          (acc, cur) => (`${acc}; ${cur}`),
          '',
        );
      rec.comment = `--> ${slot.title}; ${cmt}`;
      // Generate fields to save in rec by iterating over each capture entry
      slot.entry.capture.forEach((x) => recordMap[x.type](x, val, rec));
      // console.log(rec);
      updateProfile(row.profileId, rec);
      appendHistory(row.profileId, rec.comment, rec.prevState, rec.state);
    };
  });

  return ps;
};

export default getProfileStates;
