/* eslint-disable react/jsx-props-no-spreading, react/prop-types, no-param-reassign, no-shadow */
import React from 'react';

import { useSnackbar } from 'notistack';

// import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Ratings from 'react-ratings-declarative';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DoneIcon from '@material-ui/icons/Done';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function FunnelTransition(props) {
  const {
    val,
    row,
    setVal,
    onComplete,
    funnelStates,
    curState,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [nextState, setNextState] = React.useState(null);

  const handleTransition = (state) => {
    if (state.entry) {
      setNextState(state);
    } else {
      setNextState(null);
    }
  };

  const renderAutocomplete = (entry) => {
    let selections = (typeof entry.params === 'function') ? entry.params(val) : [...entry.params];
    selections = selections.map((x, idx) => {
      const r = { ...x };
      r.index = idx;
      return r;
    });
    const prevIndex = val[`${entry.field}Index`] || 0;
    const value = selections[prevIndex];
    // console.log('Autocomplete value:', value, val);
    return (
      <Autocomplete
        id="select-auto"
        key={entry.label}
        value={value}
        autoFocus
        autoSelect
        options={selections}
        getOptionLabel={(option) => option.name || ''}
        onChange={(e, v) => {
          if (!v) return;
          const ni = { ...val };
          ni[entry.field] = v.name;
          ni[`${entry.field}Index`] = v.index;
          setVal(ni);
        }}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={entry.label}
            margin="dense"
            fullWidth
          />
        )}
      />
    );
  };

  const renderText = (entry) => {
    const value = val[entry.field] || '';
    return (
      <TextField
        required
        value={value}
        label={entry.label}
        type="text"
        key={entry.label}
        variant="outlined"
        size="small"
        style={{ marginTop: 20 }}
        onChange={(e) => {
          const ni = { ...val };
          ni[entry.field] = e.target.value;
          setVal(ni);
        }}
      />
    );
  };

  const renderNumber = (entry) => {
    const value = val[entry.field] || '';
    const { validate } = entry;
    return (
      <TextField
        required
        value={value}
        label={entry.label}
        type="number"
        key={entry.label}
        variant="outlined"
        size="small"
        style={{ marginTop: 20 }}
        onChange={(e) => {
          if (validate) {
            const r = validate(e.target.value);
            if (r && r.error) {
              enqueueSnackbar(`Error! ${r.error}`, { variant: 'error' });
              return;
            }
          }
          const ni = { ...val };
          ni[entry.field] = e.target.value;
          setVal(ni);
        }}
      />
    );
  };

  const renderDate = (entry) => {
    const value = val[entry.field] ? new Date(val[entry.field]) : new Date();
    return (
      <div key={entry.label}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label={entry.label}
              value={value}
              onChange={(date) => {
                const ni = { ...val };
                ni[entry.field] = Math.floor(date);
                setVal(ni);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  const renderStar = (entry) => {
    const inWords = ['', 'Unacceptable', 'Poor', 'Average', 'Good', 'Excellent'];
    const value = val[entry.field] || 0;
    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Ratings
          rating={value}
          widgetDimensions="40px"
          widgetSpacings="15px"
          widgetRatedColors="orange"
          widgetEmptyColors="grey"
          changeRating={(newValue) => {
            const ni = { ...val };
            ni[entry.field] = newValue;
            setVal(ni);
          }}
        >
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
        </Ratings>
        <div>
          <Typography
            variant="body1"
            style={{ color: 'grey' }}
          >
            {inWords[value]}
          </Typography>
        </div>
      </Grid>
    );
  };

  const renderStateTransition = () => {
    if (!row) {
      return null;
    }

    const sMap = {
      autocomplete: renderAutocomplete,
      text: renderText,
      number: renderNumber,
      date: renderDate,
      rating: renderStar,
    };

    const title = `Move ${row.companyName}'s lead to `;
    const { color } = curState;
    const nextStates = [];
    curState.next.forEach((x) => {
      const n = funnelStates.find((nxt) => nxt.title === x);
      nextStates.push(n);
    });

    if (nextStates.length === 0) {
      return (
        <div>
          <h2 id="transition-modal-title">
            Cannot move profiles out of
            <span style={{ color }}>{row.state}</span>
            stage!
          </h2>
        </div>
      );
    }

    return (
      <div>
        {!nextState && (// Select State
          <div>
            <h2 id="transition-modal-title">{title}</h2>
            <Grid container direction="row">
              {nextStates.map((n) => (
                <div key={n.title}>
                  <Button
                    variant="contained"
                    style={{
                      borderColor: n.color,
                      borderWidth: 1,
                      background: `${n.color}40`,
                      color: '#1a1a1a',
                      margin: 10,
                    }}
                    onClick={() => {
                      handleTransition(n);
                    }}
                  >
                    {n.title}
                  </Button>
                </div>
              ))}
            </Grid>
          </div>
        )}

        {nextState && (//
          <div>
            <h2 id="transition-modal-title">
              {title}
              <span style={{ color: nextState.color }}>
                { nextState.title }
              </span>
            </h2>
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              {nextState.entry && nextState.entry.capture.map((e) => (sMap[e.type](e)))}
              <Button
                aria-label="next"
                color="primary"
                variant="outlined"
                startIcon={<DoneIcon />}
                style={{ marginTop: 30 }}
                onClick={() => {
                  let errDetected = false;
                  if (nextState.entry && nextState.entry.capture) {
                    nextState.entry.capture.forEach((e) => {
                      if (!val[e.field]) {
                        enqueueSnackbar(`Please fill ${e.label}`, { variant: 'error' });
                        errDetected = true;
                      }
                    });
                  }
                  if (errDetected) {
                    return;
                  }
                  if (nextState.entry && nextState.entry.onSubmit) {
                    nextState.entry.onSubmit(val, row);
                  }
                  if (onComplete) {
                    onComplete();
                  }
                }}
              >
                Submit
              </Button>
            </Grid>
          </div>
        )}
      </div>
    );
  };

  return renderStateTransition();
}
