/* eslint-disable no-console, react/jsx-props-no-spreading, react/prop-types,
   no-param-reassign, no-shadow, no-use-before-define */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import MiniStats from './MiniStats';

function Performance(props) {
  const { profiles, roles } = props;
  const [leads, setLeads] = useState([]);
  // d is last April
  const d = new Date();
  if (d.getMonth() <= 2) {
    d.setFullYear(d.getFullYear() - 1);
  }
  d.setMonth(3);
  d.setDate(1);
  d.setHours(0);
  d.setMinutes(0);
  const da = Math.floor(d);

  useEffect(() => {
    setLeads(profiles.filter((x) => (x.createdDate > da || x.deployDate > da)));
  }, [profiles]);

  return (
    <Container maxWidth="lg" style={{ padding: 4 }}>
      <Grid container spacing={3} direction="row">
        <Grid item sm={12}>
          <PersonRank leads={leads} roles={roles} />
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  profiles: state.firestore.profiles,
  roles: state.firestore.roles,
});

export default connect(mapStateToProps)(Performance);

function PersonRank(props) {
  const { leads, roles } = props;
  const [data, setData] = React.useState([]);
  // d is last April
  const d = new Date();
  if (d.getMonth() <= 2) {
    d.setFullYear(d.getFullYear() - 1);
  }
  d.setMonth(3);
  d.setDate(1);
  d.setHours(0);
  d.setMinutes(0);
  const da = Math.floor(d);

  useEffect(() => {
    const consolidated = roles.map((x) => ({
      name: x.name,
      id: x.appUid,
      pipeline: 0,
      won: 0,
      email: x.email,
    }));
    leads
      .forEach((x) => {
        if (x.state === 'lost' || x.state === 'unqualified' || x.state === 'prospecting') {
          return;
        }
        const e = consolidated.find((c) => c.id === x.ownUid);
        if (x.state === 'won' && x.deployDate > da) {
          e.won += x.estimatedRate ? x.estimatedRate * 12 : 0;
        } else {
          e.pipeline += x.estimatedRate ? x.estimatedRate * 12 : 0;
        }
      });
    consolidated.sort((a, b) => {
      if (a.won && b.won) {
        return a.won > b.won ? -1 : 1;
      } else if (a.won && !b.won) {
        return -1;
      } else if (!a.won && b.won) {
        return 1;
      }
      return (a.pipeline > b.pipeline ? -1 : 1);
    });
    // console.log(consolidated);
    setData(consolidated);
  }, [leads]);

  return (
    <Grid container>
      {data.map((l) => <MiniStats uid={l.id} name={l.name} />)}
    </Grid>
  );
}
