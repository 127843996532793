/* eslint-disable no-bitwise */
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const regions = ['Karnataka', 'Chennai', 'AP & Telengana', 'Coimbatore', 'North', 'West', 'East', 'Kerala'];

// Haversine formula: http://www.movable-type.co.uk/scripts/latlong.html
function getDistance(p1, p2) {
  const R = 6371; // Mean radius of Earth in km
  const lat1 = (p1.latitude * Math.PI) / 180;
  const lat2 = (p2.latitude * Math.PI) / 180;
  const deltaLat = ((p2.latitude - p1.latitude) * Math.PI) / 180;
  const deltaLon = ((p2.longitude - p1.longitude) * Math.PI) / 180;

  const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2)
        + Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

function pad2(num) {
  const s = `00${num}`;
  return s.substring(s.length - 2);
}

function random16Hex() { return (0x10000 | Math.random() * 0x10000).toString(16).substr(1); }

function random32Hex() { return random16Hex() + random16Hex(); }

function random64Hex() { return random32Hex() + random32Hex(); }

/** random 128-bit number in canonical uuid format. all bits are random. */
function UUID() {
  return `${random16Hex()}${random16Hex()}-${random16Hex()}-${random16Hex()}-${random16Hex()}-${random16Hex()}${random16Hex()}${random16Hex()}`;
}

function timestamp() {
  return Math.floor(new Date());
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function printTime(d) {
  return `${d.getHours()}:${d.getMinutes()}`;
}

function printDate(d) {
  return `${d.getDate()}-${monthNames[d.getMonth()]}-${d.getFullYear()}`;
}

// Convert hours to clock ticks
function hours2ticks(hrs) {
  return hrs * 3600000;
}

// Convert minutes to clock ticks
function minutes2ticks(mins) {
  return mins * 60000;
}

function deepCopy(o) {
  return JSON.parse(JSON.stringify(o));
}

// Check if object is empty
function isEmpty(obj) {
  return (Object.keys(obj).length === 0 && obj.constructor === Object);
}

function days2words(days) {
  return `${Math.round(days / 30)}m, ${days % 30}d`;
}

function months2words(months) {
  return `${Math.round(months / 12)}y, ${months % 12}m`;
}

function lakhs2words(ths) {
  return `${Math.round(ths / 100)}.${ths % 100} Lakh`;
}

function downloadURI(uri, name) {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function downloadNewWindow(uri, name) {
  window.open(uri, name);
}

async function sha256(message) {
  const data = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

const strToInt = (str) => {
  if (str && typeof str === 'string') {
    return parseInt(str, 10).isNaN ? 0 : parseInt(str, 10);
  }
  if (str && typeof str === 'number') {
    return str;
  }
  return 0;
};

const strToFloat = (str) => {
  if (str && typeof str === 'string') {
    // eslint-disable-next-line
    return isNaN(parseFloat(str)) ? 0 : parseFloat(str);
  }
  if (str && typeof str === 'number') {
    return str;
  }
  return 0;
};

export {
  monthNames,
  regions,
  getDistance,
  pad2,
  UUID,
  random16Hex,
  random32Hex,
  random64Hex,
  timestamp,
  sleep,
  printDate,
  printTime,
  hours2ticks,
  minutes2ticks,
  days2words,
  months2words,
  lakhs2words,
  deepCopy,
  isEmpty,
  downloadURI,
  downloadNewWindow,
  sha256,
  strToInt,
  strToFloat,
};
