/* eslint-disable no-console, react/jsx-props-no-spreading, react/prop-types,
   no-param-reassign, no-shadow, no-use-before-define */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

function MiniStats(props) {
  const { profiles, uid, name } = props;
  const [leads, setLeads] = useState([]);
  // d is last April
  const d = new Date();
  if (d.getMonth() <= 2) {
    d.setFullYear(d.getFullYear() - 1);
  }
  d.setMonth(3);
  d.setDate(1);
  d.setHours(0);
  d.setMinutes(0);
  const da = Math.floor(d);

  useEffect(() => {
    const l = profiles.filter((x) => x.ownUid === uid && (x.createdDate > da || x.deployDate > da));
    setLeads(l);
  }, [profiles, uid]);

  const getStats = (option) => {
    let pipe = [];
    if (option === 'pipeline') {
      pipe = leads.filter((x) => x.state !== 'won' && x.state !== 'unqualified' && x.state !== 'lost' && x.state !== 'prospecting');
    } else {
      pipe = leads.filter((x) => x.state === 'won' && x.deployDate > da);
    }
    const pipeRevenue = pipe
      .map((x) => (x.estimatedRate ? x.estimatedRate * 12 : 0))
      .reduce((a, c) => a + c, 0)
      .toFixed(2);
    return ([pipeRevenue, pipe.length]);
  };

  const renderPipelineStats = () => {
    const [r, l] = getStats('pipeline');
    return (
      <RevenueStats
        title="Pipeline"
        leads={l}
        revenue={r}
        owner={name}
        color="#00ccff"
      />
    );
  };

  const renderWonStats = () => {
    const [r, l] = getStats('won');
    return (
      <RevenueStats
        title="Won !"
        leads={l}
        revenue={r}
        owner={name}
        color="#37c871"
      />
    );
  };

  const renderConversion = () => {
    // eslint-disable-next-line
    const [pRev, pLeads] = getStats('pipeline'); // eslint-disable-next-line
    const [wRev, wLeads] = getStats('won');
    const denom = pLeads + wLeads;
    const conversion = denom > 0 ? ((wLeads * 100) / denom).toFixed(0) : 0;

    return (
      <Grid
        container
        direction="column"
        style={{
          borderRadius: 5,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#afe9c6',
          padding: 5,
          margin: 5,
          justifyContent: 'center',
          alignItems: 'center',
          color: '#4d4d4d',
        }}
      >
        <Grid item style={{ fontSize: '1.8em' }}>
          {`${conversion} %`}
        </Grid>
        <Grid item style={{ fontSize: '0.8em' }}>
          CONVERSION
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction="row">
      <Grid item sm={5} style={{ padding: 5 }}>
        {renderPipelineStats()}
      </Grid>
      <Grid item sm={5} style={{ padding: 5 }}>
        {renderWonStats()}
      </Grid>
      <Grid item sm={2} style={{ padding: 5 }}>
        {renderConversion()}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  profiles: state.firestore.profiles,
  roles: state.firestore.roles,
});

export default connect(mapStateToProps)(MiniStats);

function RevenueStats(props) {
  const {
    title,
    leads,
    revenue,
    owner,
    color,
  } = props;
  return (
    <Grid
      container
      direction="row"
      style={{
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#afe9c6',
        padding: 5,
        margin: 5,
        justifyContent: 'center',
        alignItems: 'center',
        color: '#4d4d4d',
      }}
    >
      <Grid item sm={4}>
        <Grid container direction="column">
          <Grid item style={{ fontSize: '1.2em' }}>
            {title.toUpperCase()}
          </Grid>
          <Grid item style={{ fontSize: '0.8em' }}>
            {owner}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={4}>
        <Grid container direction="column">
          <Grid item style={{ fontSize: '1.7em' }}>
            {leads}
          </Grid>
          <Grid item style={{ fontSize: '0.8em' }}>
            LEADS | YTD
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={4}>
        <Grid container direction="column">
          <Grid item style={{ fontSize: '1.7em', color: color || '#4d4d4d' }}>
            {revenue}
          </Grid>
          <Grid item style={{ fontSize: '0.8em' }}>
            REVENUE (LAKHS) | YTD
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
