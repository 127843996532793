import React from 'react';
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import routes from '../routes';

export default function MainMenu() {
  const [selected, setSelected] = React.useState(0); // Selected menu item
  const toDisplay = [...routes];
  if (global.role && !global.role.isAdmin) { // Remove items
    let idx = toDisplay.findIndex((x) => x.title === 'Users');
    toDisplay.splice(idx, 1);
    // idx = toDisplay.findIndex((x) => x.title === 'Performance');
    // toDisplay.splice(idx, 1);
  }
  return (
    <List>
      {toDisplay.map((x, idx) => (
        <NavLink
          to={x.path}
          key={x.path}
          style={{ textDecoration: 'none' }}
        >
          <ListItem
            key={idx}
            button
            selected={selected === idx}
            onClick={() => {
              setSelected(idx);
            }}
          >
            <ListItemIcon>
              {x.icon}
            </ListItemIcon>
            <ListItemText primary={x.title} />
          </ListItem>
        </NavLink>
      ))}
    </List>
  );
}
