/* eslint-disable no-console, react/jsx-props-no-spreading, react/prop-types,
   no-param-reassign, no-shadow, no-use-before-define */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { useSnackbar } from 'notistack';
import ExcelJS from 'exceljs';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import Badge from '@material-ui/core/Badge';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fade from '@material-ui/core/Fade';
import Autocomplete from '@material-ui/lab/Autocomplete';

/*
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
*/
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
// import MessageIcon from '@material-ui/icons/Message';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import * as Utils from '../utils';

// import 'react-phone-number-input/style.css';

import HistoryWindow from './HistoryWindow';
import TeamAvatars from './TeamAvatars';
import MyndStepper from './MyndStepper';
import FunnelTransition from './FunnelTransition';
import MiniStats from './MiniStats';
// import CandidateDetails from './CandidateDetails';
import getProfileStates from '../profileStates';

// btns for issue type
const colors = [
  'burlywood', 'cadetblue', 'chocolate', 'cornflowerblue', 'coral', 'darkkhaki',
  'darkorange', 'darksalmon', 'darkseagreen', 'deepskyblue', 'firebrick',
  'forestgreen', 'goldenrod',
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formControl: {
    minWidth: 130,
    marginTop: 10,
  },
  fixedHeight: {
    height: 240,
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      // maxWidth: 40,
      width: '100%',
      backgroundColor: '#37c871',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    color: '#4d4d4d',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(0),
    '&:hover': {
      color: '#37c871e0',
    },
    '&$selected': {
      color: '#37c871ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#2c7844',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const defaultHistory = {
  setHistory: false,
  row: null,
};

function Profiles(props) {
  const { roles } = props;
  const { orgId } = global.role;
  const colRef = window.firestore.collection('data').doc(orgId).collection('profiles');
  const histRef = window.firestore.collection('data').doc(orgId).collection('history');
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0); // Tab index
  const [ProfileStates] = React.useState(getProfileStates());
  const [showModal, setShowModal] = React.useState(false);
  const [row, setRow] = React.useState(null);
  const [val, setVal] = React.useState({});
  const [users, setUsers] = React.useState({});
  const [history, setHistory] = React.useState(defaultHistory);
  const [openAssign, setOpenAssign] = React.useState(false);
  const [assignee, setAssignee] = React.useState('');
  const [regionFilter, setRegionFilter] = React.useState('');
  const [personFilter, setPersonFilter] = React.useState('');
  const [dateFilter, setDateFilter] = React.useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const today = new Date();
  const lastWeek = Math.floor((new Date()).setDate(today.getDate() - 7));

  // setColumns(getTableColumns());

  useEffect(() => {
    const u = {};
    roles.forEach((r) => {
      u[r.appUid] = { name: r.name, email: r.email, teams: r.teams };
    });
    setUsers(u);
  }, [roles ? roles.length : 0]);

  const handleTab = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const strToInt = (str) => {
    if (str && typeof str === 'string') {
      return parseInt(str, 10).isNaN ? 0 : parseInt(str, 10);
    }
    if (str && typeof str === 'number') {
      return str;
    }
    return 0;
  };

  const strToFloat = (str) => {
    if (str && typeof str === 'string') {
      // eslint-disable-next-line
      return isNaN(parseFloat(str)) ? 0 : parseFloat(str);
    }
    if (str && typeof str === 'number') {
      return str;
    }
    return 0;
  };

  const exportExcel = async (columns, data) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Myndrix auto generator';
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 1,
        visibility: 'visible',
      },
    ];
    const sheet = workbook.addWorksheet('Leads', {
      headerFooter: {
        firstHeader: '',
        firstFooter: '',
      },
    });
    // Set Frozen View - ie. top 1 rows dont scroll
    sheet.views = [
      { state: 'frozen', xSplit: 0, ySplit: 1 },
    ];
    // Set column widths
    const cols = [
      { header: 'Company', key: 'companyName', width: 28 },
      { header: 'Stage', key: 'state', width: 12 },
      { header: 'Contact', key: 'contactName', width: 20 },
      { header: 'Designation', key: 'contactDesignation', width: 20 },
      { header: 'Phone', key: 'contactPhone', width: 14 },
      { header: 'Email', key: 'contactEmail', width: 32 },
      { header: 'Type', key: 'leadType', width: 14 },
      { header: 'Source', key: 'leadSource', width: 14 },
      { header: 'Region', key: 'region', width: 14 },
      { header: 'Sales Person', key: 'userName', width: 20 },
      { header: 'Email', key: 'userEmail', width: 32 },
      { header: 'Estimated Strength', key: 'estimatedStrength', width: 16 },
      { header: 'Estimated Monthly Billing', key: 'estimatedRate', width: 16 },
      { header: 'Industry Segment', key: 'industrySegment', width: 20 },
      { header: 'Type of Service', key: 'serviceType', width: 20 },
      { header: 'Contract confirmation date', key: 'contractDate', width: 20 },
      { header: 'Deployment date', key: 'deployDate', width: 20 },
      { header: 'Approved strength', key: 'approvedStrength', width: 16 },
      { header: 'Comments', key: 'comments', width: 40 },
    ];

    sheet.columns = cols;
    // Set header styling
    const heading = sheet.getRow(1);
    heading.font = {
      name: 'Arial',
      family: 4,
      size: 11,
      color: { argb: '4d4d4d' },
      underline: false,
      bold: true,
    };
    heading.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    heading.height = 25;
    heading.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'AFE9C6' },
      };
    });
    // Fill data
    data
      .filter((x) => isSameTeam(x))
      .forEach((x, idx) => {
        const row = sheet.getRow(idx + 2);
        row.values = [
          x.companyName,
          x.state,
          x.contactName,
          x.contactDesignation,
          x.contactPhone,
          x.contactEmail,
          x.leadType,
          x.leadSource,
          x.region,
          x.userName,
          x.userEmail,
          strToInt(x.estimatedStrength),
          strToFloat(x.estimatedRate),
          x.industrySegment,
          x.serviceType,
          x.contractDate ? new Date(x.contractDate) : '',
          x.deployDate ? new Date(x.deployDate) : '',
          strToInt(x.approvedStrength),
          x.comment,
        ];
      });

    workbook.xlsx.writeBuffer().then((data) => {
      const today = new Date();
      const blob = new Blob(
        [data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      );
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `pileline-${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const appendMessage = (text, profileId, extra, state) => {
    extra = extra || '';
    const msg = {
      time: Utils.timestamp(),
      authorId: global.role.appUid,
      authorOrg: global.role.orgId,
      message: text,
      uploaded: extra,
      state,
    };
    histRef.doc(profileId).set({ [msg.time]: msg }, { merge: true });
  };

  const appendHistory = (rec, com) => {
    const pid = rec.origProfileId ? rec.origProfileId : rec.profileId;
    const state = com.state ? com.state : rec.state;
    appendMessage(com.history, pid, null, state);
  };

  const shorten = (comment) => {
    if (comment && comment.length > 43) {
      return `${comment.slice(0, 43)} ...`;
    }
    return comment;
  };

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <div className={classes.modalPaper}>
          <FunnelTransition
            val={val}
            row={row}
            setVal={setVal}
            funnelStates={ProfileStates}
            curState={ProfileStates[tabIndex]}
            onComplete={() => {
              setShowModal(false);
            }}
          />
        </div>
      </Fade>
    </Modal>
  );

  const renderRegionFilter = () => {
    const selections = Utils.regions;
    return (
      <Autocomplete
        id="select-region"
        value={regionFilter}
        autoFocus
        autoSelect
        options={selections}
        getOptionLabel={(option) => option}
        onChange={(e, v) => {
          setRegionFilter(v);
        }}
        style={{ width: '12em', marginRight: 10 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter by Region"
            margin="dense"
          />
        )}
      />
    );
  };

  const renderPersonFilter = () => {
    const selections = roles ? roles.filter((x) => x.isRegular && x.email !== 'ajay@myndrix.com').map((y) => y.name) : [];
    return (
      <Autocomplete
        id="select-region"
        value={personFilter}
        autoFocus
        autoSelect
        options={selections}
        getOptionLabel={(option) => option}
        onChange={(e, v) => {
          setPersonFilter(v);
        }}
        style={{ width: '12em', marginRight: 10 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter by Person"
            margin="dense"
          />
        )}
      />
    );
  };

  const renderDateFilter = () => {
    const currentState = ProfileStates[tabIndex].title;
    if (currentState !== 'won') {
      return null;
    }
    const selections = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
      .map((x) => {
        const today = new Date();
        today.setMonth(today.getMonth() - x);
        return {
          month: today.getMonth(),
          year: today.getFullYear(),
          title: `${Utils.monthNames[today.getMonth()]}, ${today.getFullYear()}`,
        };
      });
    return (
      <Autocomplete
        id="select-region"
        value={dateFilter}
        autoFocus
        autoSelect
        options={selections}
        getOptionLabel={(option) => option.title}
        onChange={(e, v) => {
          setDateFilter(v);
        }}
        style={{ width: '12em', marginRight: 10 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter by Month"
            margin="dense"
          />
        )}
      />
    );
  };

  const renderAssignDialog = () => {
    const assignees = roles ? roles.filter((x) => x.isRegular && x.email !== 'ajay@myndrix.com') : [];
    return (
      <Dialog
        open={openAssign}
        onClose={() => setOpenAssign(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reassign Lead</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the email id of the person you want to assign this lead
          </DialogContentText>
          <Autocomplete
            id="select-assignee"
            autoSelect
            options={assignees.map((x) => ({ title: x.name, id: x.appUid }))}
            getOptionLabel={(option) => option.title}
            onInputChange={(e, v) => setAssignee(v)}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Name of the salesperson"
                margin="dense"
                type="email"
                fullWidth
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              console.log(assignee);
              setOpenAssign(false);
              setAssignee('');
              setRow(null);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              console.log(assignee);
              if (!assignee) {
                enqueueSnackbar('No person selected to assign', { variant: 'error' });
              } else {
                const found = roles.find((u) => u.name === assignee);
                colRef.doc(row.profileId)
                  .set({ ownUid: found.appUid, modifiedDate: Utils.timestamp() }, { merge: true })
                  .then(() => {
                    const msg = `${row.companyName}'s lead reassigned to ${assignee}`;
                    enqueueSnackbar(msg, { variant: 'success' });
                    appendHistory(row, { history: `--> reassign; ${msg}` });
                  })
                  .catch((err) => enqueueSnackbar(err, { variant: 'error' }));
              }
              setOpenAssign(false);
              setAssignee('');
              setRow(null);
            }}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const isSameTeam = (profile) => {
    // if (global.role.isAdmin) return true;
    const uteams = users[profile.ownUid] ? users[profile.ownUid].teams : null;
    if (!uteams) return false;
    let found = false;
    uteams.forEach((t) => {
      if (global.role.teams && global.role.teams.find((gt) => gt === t)) {
        found = true;
        // console.log('found: ', users[profile.ownUid].name + ' ', );
      }
    });
    return found;
  };

  const getTableColumns = () => {
    const currentState = ProfileStates[tabIndex].title;
    const cols = [
      // 0
      {
        title: 'Company',
        field: 'companyName',
      },
      // 1
      {
        title: 'Annualised Value',
        field: 'estimatedStrength',
        render: (rowData) => (
          <span>
            {rowData.estimatedStrength ? `${(rowData.estimatedRate * 12).toFixed(2)} Lakhs` : 'Not Qualified'}
          </span>
        ),
      },
      // 2
      {
        title: 'Region',
        field: 'region',
      },
      // 3
      {
        title: 'Salesperson',
        field: 'userName',
        width: '10em',
        render: (rowData) => {
          const { teams, userName } = rowData;
          return (
            <div>
              <TeamAvatars teams={teams} />
              {userName}
            </div>
          );
        },
      },
      // 4
      {
        title: 'Comments',
        field: 'comment',
        render: (rowData) => (
          <div>
            {shorten(rowData.comment)}
            {rowData.modifiedDate < lastWeek && (
              <div style={{ fontSize: 'x-small', color: '#d40000' }}>
                {`Updated: ${Utils.printDate(new Date(rowData.modifiedDate))}`}
              </div>
            )}
            {rowData.modifiedDate >= lastWeek && (
              <div style={{ fontSize: 'x-small' }}>
                {`Updated: ${Utils.printDate(new Date(rowData.modifiedDate))}`}
              </div>
            )}
          </div>
        ),
      },
    ];
    // Add updated field for inbox state
    if (currentState === 'inbox') {
      cols.splice(2, 0, { title: 'Added on', field: 'addedOn' });
    }
    if (currentState === 'all') {
      cols.splice(1, 0, {
        title: 'Stage',
        field: 'state',
        render: (rowData) => {
          const { state } = rowData;
          const prof = ProfileStates.find((x) => x.title === rowData.state);
          const color = prof ? prof.color : '#808080';
          return (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                borderColor: color,
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: 5,
                background: `${color}40`,
              }}
            >
              {state}
            </Grid>
          );
        },
      });
    }
    // const r = memoize(cols);
    // console.log('returning: ', r);
    return cols;
  };

  const renderProfileTable = () => {
    const currentState = ProfileStates[tabIndex].title;
    const { profiles } = props;
    // Show only profiles in user selected state
    let d0 = [];
    if (currentState !== 'all') {
      d0 = profiles ? profiles.filter((x) => x.state === currentState) : [];
    } else {
      d0 = profiles;
    }
    // Show only profiles from same team
    // const data = d0.filter((x) => isSameTeam(x));
    // Filter region
    const data = d0
      .filter((x) => {
        if (!dateFilter || currentState !== 'won') {
          return true;
        }
        if (!x.deployDate) {
          return false;
        }
        const wonDate = new Date(x.deployDate);
        if (wonDate.getMonth() === dateFilter.month && wonDate.getFullYear() === dateFilter.year) {
          return true;
        }
        return false;
      })
      .filter((x) => (!regionFilter ? true : x.region === regionFilter))
      .filter((x) => (!personFilter ? true : x.userName === personFilter));
    data.forEach((x) => {
      // Show user name
      x.userName = users[x.ownUid] ? users[x.ownUid].name : '';
      x.userEmail = users[x.ownUid] ? users[x.ownUid].email : '';
      x.teams = users[x.ownUid] ? users[x.ownUid].teams : [];

      // Add 'addedOn' field for inbox
      if (currentState === 'inbox') {
        const d = x.createdDate ? new Date(x.createdDate) : new Date(2020, 2, 19);
        const month = Utils.pad2(d.getMonth() + 1);
        x.addedOn = `${d.getFullYear()}-${month}-${Utils.pad2(d.getDate())}`;
      }
    });
    // Filter by modified time
    d0 = profiles.sort((a, b) => {
      if (a.modifiedDate && b.modifiedDate) {
        return a.modifiedDate < b.modifiedDate ? 1 : -1;
      } if (a.modifiedDate && !b.modifiedDate) {
        return -1;
      } if (!a.modifiedDate && b.modifiedDate) {
        return 1;
      } if (a.createdDate && !b.createdDate) {
        return -1;
      } if (!a.createdDate && b.createdDate) {
        return 1;
      }
      return a.createdDate < b.createdDate ? 1 : -1;
    });

    const actions = [
      {
        icon: () => <DoubleArrowIcon style={{ color: '#808080', fontSize: 21 }} />,
        tooltip: 'Process this profile',
        onClick: (e, r) => {
          setShowModal(true);
          setRow(r);
        },
      },
    ];
    if (currentState === 'all') {
      actions.splice(0, 1);
    }
    if (global.role && global.role.isManager) {
      actions.push({
        icon: () => <ArrowForwardIosIcon style={{ color: '#808080', fontSize: 21 }} />,
        tooltip: 'Reassign profile',
        onClick: (e, r) => {
          setRow(r);
          setOpenAssign(true);
        },
      });
    }

    const options = {
      headerStyle: {
        backgroundColor: '#afe9c6e0',
      },
      exportButton: true,
      exportCsv: exportExcel,
      filtering: true,
      padding: 'dense',
      pageSizeOptions: [10, 20, 40],
      pageSize: 20,
    };
    const rowAction = (event, rdata) => {
      // enqueueSnackbar('Row clicked ' + rdata.name, {variant: 'info'});
      if (isSameTeam(rdata)) {
        setHistory({ show: true, row: rdata });
      }
    };

    return (
      <div>
        <MaterialTable
          title=""
          columns={getTableColumns()}
          data={data}
          actions={actions}
          options={options}
          isLoading={false}
          onRowClick={rowAction}
        />
      </div>
    );
  };

  const renderTabs = () => {
    const { profileCount } = props;
    return (
      <Grid item xs={12}>
        <Paper>
          <StyledTabs
            value={tabIndex}
            onChange={handleTab}
            variant="scrollable"
            scrollButtons="auto"
          >
            {ProfileStates.map((x) => (
              <StyledTab
                label={<Badge badgeContent={profileCount[x.title]}>{x.title.toUpperCase()}</Badge>}
                key={x.title}
              />
            ))}
          </StyledTabs>
          {renderProfileTable()}
        </Paper>
      </Grid>
    );
  };

  const renderHistoryWindow = () => {
    const h = Utils.deepCopy(history);
    return (
      <HistoryWindow
        disabled={!h.show}
        row={h.row}
        users={users}
        onClose={() => {
          h.show = false;
          setHistory(h);
        }}
      />
    );
  };

  const getLeadSources = (val) => {
    const { leadType } = val;
    if (leadType === 'inbound') {
      return [
        'website',
        'reference',
        'existing customer',
        'previously a customer',
        'advertisement',
        'other',
      ]
        .map((x, idx) => ({ name: x, color: colors[idx] }));
    }
    return [
      'email/phone list',
      'existing customer expanding',
      'previously a customer',
      'previously was not selected',
      'tender',
      'other',
    ]
      .map((x, idx) => ({ name: x, color: colors[idx] }));
  };

  const renderStepper = () => {
    const spec = {
      title: 'New Prospect',
      buttonColor: '#5fd38d',
      onSubmit: (val) => {
        const rec = {};
        const id = Utils.UUID();
        const t = Math.floor(new Date());
        spec.steps.forEach((x) => {
          rec[x.field] = val[x.field] || '';
        });
        rec.profileId = id;
        rec.createdDate = t;
        rec.modifiedDate = t;
        rec.createUid = global.role.appUid;
        rec.ownUid = global.role.appUid;
        rec.comment = '';
        rec.state = 'prospecting';
        window
          .firestore
          .collection('data')
          .doc(global.role.orgId)
          .collection('profiles')
          .doc(id)
          .set(rec, { merge: true })
          .catch((err) => {
            console.log('Could not add to funnel', err);
          });
        appendHistory(rec, { history: `New lead for ${rec.companyName}` });
      },
      steps: [
        {
          // 0
          title: 'Company Name',
          type: 'text',
          field: 'companyName',
          nxtAction: (val) => (
            val.companyName ? { error: false } : { error: true, message: 'Please enter a company name' }
          ),
        },
        {
          // 1
          title: 'Type of Lead',
          type: 'buttonGroup',
          field: 'leadType',
          params: ['inbound', 'outbound'].map((x, idx) => ({ name: x, color: colors[idx] })),
          nxtAction: (val) => (
            val.leadType ? { error: false } : { error: true, message: 'Please select a lead type' }
          ),
        },
        {
          // 2
          title: 'Source of Lead',
          type: 'buttonGroup',
          field: 'leadSource',
          params: getLeadSources,
          nxtAction: (val) => (
            val.leadSource ? { error: false } : { error: true, message: 'Please select a source' }
          ),
        },
        {
          // 3
          title: 'Region',
          type: 'buttonGroup',
          field: 'region',
          params: Utils.regions.map((x, idx) => ({ name: x, color: colors[idx] })),
          nxtAction: (val) => (
            val.region ? { error: false } : { error: true, message: 'Please select a region' }
          ),
        },
        {
          // 4
          title: 'Customer Point of Contact',
          type: 'text',
          field: 'contactName',
          nxtAction: (val) => (
            val.contactName ? { error: false } : { error: true, message: 'Please give a name' }
          ),
        },
        {
          // 5
          title: 'Designation (Optional)',
          type: 'text',
          field: 'contactDesignation',
        },
        {
          // 6
          title: 'Customer Phone',
          type: 'text',
          field: 'contactPhone',
          nxtAction: (val) => (
            val.contactPhone ? { error: false } : { error: true, message: 'Please give a phone number' }
          ),
        },
        {
          // 7
          title: 'Customer Email',
          type: 'email',
          field: 'contactEmail',
          nxtAction: (val) => (
            val.contactEmail ? { error: false } : { error: true, message: 'Please give an email ID' }
          ),
        },
        {
          // 8
          title: 'Industry Segment',
          type: 'buttonGroup',
          field: 'industrySegment',
          params: ['Apartment', 'Offices', 'FMS Partner', 'Factory', 'Mall', 'Shop/Restaurant', 'Home', 'Other']
            .map((x, idx) => ({ name: x, color: colors[idx] })),
        },
        {
          // 9
          title: 'Type of Service',
          type: 'buttonGroup',
          field: 'serviceType',
          params: ['Security', 'FMS', 'Other'].map((x, idx) => ({ name: x, color: colors[idx] })),
        },
      ],
    };
    return <MyndStepper spec={spec} />;
  };

  const renderHeader = () => (
    <Grid container direction="row">
      <Grid item sm={12}>
        <MiniStats uid={global.role.appUid} name={global.role.name} />
      </Grid>
      <Grid item sm={3} style={{ marginTop: 10 }}>
        {renderStepper()}
      </Grid>
      <Grid item sm={3}>
        {renderDateFilter()}
      </Grid>
      <Grid item sm={3}>
        {renderRegionFilter()}
      </Grid>
      <Grid item sm={3}>
        {renderPersonFilter()}
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        { renderHeader() }
        { renderTabs() }
        { renderModal() }
        { renderAssignDialog() }
        { renderHistoryWindow() }
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  profiles: state.firestore.profiles,
  roles: state.firestore.roles,
  profileCount: state.firestore.profileCount,
});

export default connect(mapStateToProps)(Profiles);
