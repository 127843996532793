/* eslint-disable react/jsx-props-no-spreading, react/prop-types, no-param-reassign, no-shadow */
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

// import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Ratings from 'react-ratings-declarative';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DoneIcon from '@material-ui/icons/Done';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import * as Utils from '../utils';

function MyndForm(props) {
  const { spec, initValues } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [errors, setErrors] = useState([]);
  const [val, setVal] = useState({});
  const [edit, setEdit] = useState([]);

  const setValueGeneric = (entry, tmpVal) => {
    tmpVal[entry.field] = entry.value;
  };

  const setValueAndIndex = (entry, tmpVal) => {
    const idx = entry.params.findIndex((x) => x === entry.value);
    setValueGeneric({ value: idx, field: `${entry.field}Index` }, tmpVal);
    setValueGeneric(entry, tmpVal);
  };

  const setValMap = {
    autocomplete: setValueAndIndex,
    text: setValueGeneric,
    number: setValueGeneric,
    date: setValueGeneric,
    rating: setValueGeneric,
    buttonGroup: setValueAndIndex,
  };

  useEffect(() => {
    const tmpVal = {};
    spec.items.forEach((x) => {
      if (x.value) {
        setValMap[x.type](x, tmpVal);
      }
    });
    setVal(initValues || {});
    setEdit(spec.items.map(() => false));
    setErrors(spec.items.map(() => false));
  }, [spec]);

  const renderAutocomplete = (entry) => {
    let selections = (typeof entry.params === 'function') ? entry.params(val) : [...entry.params];
    selections = selections.map((x, idx) => ({ name: x, index: idx }));
    return (
      <Autocomplete
        id="select-auto"
        key={entry.field}
        value={val[entry.field] ? selections[selections.findIndex((x) => x.name === val[entry.field])] : ''}
        autoFocus
        autoSelect
        options={selections}
        getOptionLabel={(option) => option.name || ''}
        onChange={(e, v) => {
          if (!v) return;
          const ni = { ...val };
          ni[entry.field] = v.name;
          ni[`${entry.field}Index`] = v.index;
          setVal(ni);
        }}
        style={{ width: '67%', marginTop: 0, marginLeft: 5 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select from list"
            margin="dense"
            fullWidth
          />
        )}
      />
    );
  };

  const colors = ['#80b3ff90', '#5fd38d90', '#afafe990', '#5fd3bc90'];
  const renderButtonGroup = (entry) => {
    const btnIdx = val[`${entry.field}Index`] || 0;
    const selections = (typeof entry.params === 'function') ? entry.params(val) : [...entry.params];
    return (
      <div>
        {selections.map((x, idx) => {
          const bdrColor = idx === btnIdx ? 'black' : 'white';
          return (
            <Button
              key={x}
              size="small"
              style={{
                backgroundColor: colors[idx % colors.length],
                color: '#4d4d4d',
                margin: 5,
                borderColor: bdrColor,
                borderWidth: 2,
                borderStyle: 'solid',
              }}
              onClick={() => {
                const ni = { ...val };
                ni[entry.field] = x;
                ni[`${entry.field}Index`] = idx;
                setVal(ni);
              }}
            >
              {x.toUpperCase()}
            </Button>
          );
        })}
      </div>
    );
  };

  const renderText = (entry) => (
    <TextField
      required
      value={val[entry.field] || ''}
      label={entry.label}
      type="text"
      key={entry.label}
      variant="outlined"
      size="small"
      style={{ marginTop: 10, width: '80%' }}
      onChange={(e) => {
        const ni = { ...val };
        ni[entry.field] = e.target.value;
        setVal(ni);
      }}
    />
  );

  const renderNumber = (entry) => {
    const value = val[entry.field] || '';
    return (
      <TextField
        required
        value={value}
        label={entry.label}
        type="number"
        key={entry.label}
        variant="outlined"
        size="small"
        style={{ marginTop: 10, width: '23' }}
        onChange={(e) => {
          const ni = { ...val };
          ni[entry.field] = parseFloat(e.target.value);
          setVal(ni);
        }}
      />
    );
  };

  const renderDate = (entry) => {
    const value = val[entry.field] ? new Date(val[entry.field]) : new Date();
    return (
      <div key={entry.label}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container style={{ marginLeft: 5 }}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label={entry.label}
              value={value}
              onChange={(date) => {
                const ni = { ...val };
                ni[entry.field] = Math.floor(date);
                setVal(ni);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  const renderStar = (entry) => {
    const inWords = ['', 'Unacceptable', 'Poor', 'Average', 'Good', 'Excellent'];
    const value = val[entry.field] || 0;
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 20, marginLeft: 5 }}>
        <Ratings
          rating={value}
          widgetDimensions="27px"
          widgetSpacings="15px"
          widgetRatedColors="orange"
          widgetEmptyColors="grey"
          changeRating={(newValue) => {
            const ni = { ...val };
            ni[entry.field] = newValue;
            setVal(ni);
          }}
        >
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
        </Ratings>
        <div>
          <Typography
            variant="body1"
            style={{ color: 'grey' }}
          >
            {inWords[value]}
          </Typography>
        </div>
      </Grid>
    );
  };

  const renderAsText = (entry) => (
    <Typography
      variant="body1"
      style={{ color: '#1a1a1a', marginLeft: 23, marginTop: 7 }}
    >
      {val[entry.field] ? val[entry.field] : 'Not Specified'}
    </Typography>
  );

  const renderDateAsText = (entry) => (
    <Typography
      variant="body1"
      style={{ color: '#1a1a1a', marginLeft: 23, marginTop: 7 }}
    >
      {val[entry.field] ? Utils.printDate(new Date(val[entry.field])) : 'Not Specified'}
    </Typography>
  );

  const sMap = {
    autocomplete: { edit: renderAutocomplete, readOnly: renderAsText },
    text: { edit: renderText, readOnly: renderAsText },
    number: { edit: renderNumber, readOnly: renderAsText },
    date: { edit: renderDate, readOnly: renderDateAsText },
    rating: { edit: renderStar, readOnly: renderStar },
    buttonGroup: { edit: renderButtonGroup, readOnly: renderAsText },
  };

  const checkErrors = (idx) => {
    const es = spec.items.map((e, i) => (
      !!e.mandatory && i < idx && !val[spec.items[i].field]
    ));
    setErrors(es);
    return es.reduce((cur, acc) => !!acc || cur);
  };

  const renderFormComponents = () => (
    <Grid container direction="row">
      {spec.items.map((x, idx) => (
        <Grid
          item
          xs={12}
          sm={6}
          // md={4}
          style={{ padding: 5 }}
          key={x.field}
        >
          <Grid
            container
            direction="column"
            onFocus={() => checkErrors(idx)}
            style={{
              height: '100%',
              borderStyle: 'solid',
              borderWidth: x.mandatory ? 2 : 1,
              borderColor: errors[idx] ? '#ff5555a0' : '#afe6c6a0',
              borderRadius: 3,
              padding: 5,
              background: 'white',
              position: 'relative',
            }}
          >
            {!edit[idx] && x.editable && (
              <Button
                size="small"
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  color: '#37c871',
                }}
                onClick={() => {
                  const e = [...edit];
                  e[idx] = true;
                  setEdit(e);
                }}
              >
                Edit
              </Button>
            )}
            <Grid item style={{ color: '#4d4d4d' }}>
              {`${idx + 1}. ${x.title} ${x.mandatory ? '*' : ''}`}
            </Grid>
            <Grid>
              {edit[idx] && sMap[x.type].edit(x)}
              {!edit[idx] && sMap[x.type].readOnly(x)}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  const isPromise = (promise) => (
    !!promise && typeof promise.then === 'function'
  );

  const renderForm = () => (
    <Grid
      container
      direction="column"
      spacing={3}
      style={{ flexGrow: 1, background: '#f9f9f9' }}
    >
      <Grid item>
        {renderFormComponents()}
      </Grid>
      {edit.length > 0 && edit.reduce((cur, acc) => (cur || acc)) && (
        <Button
          aria-label="next"
          color="primary"
          variant="outlined"
          startIcon={<DoneIcon />}
          style={{
            margin: 30,
            width: 130,
            background: 'white',
            borderColor: spec.buttonColor || '#3e6ef3',
            color: spec.buttonColor || '#3e6ef3',
          }}
          onClick={() => {
            if (checkErrors(spec.items.length - 1)) {
              enqueueSnackbar('Please correct the errors before submitting..', { variant: 'error' });
              return;
            }
            if (spec.onSubmit) {
              const rec = {};
              spec.items.forEach((x, idx) => {
                if (edit[idx]) {
                  rec[x.field] = val[x.field];
                }
              });
              const p = spec.onSubmit(rec);
              if (isPromise(p)) {
                p.then(() => {
                  setEdit(edit.map(() => false));
                });
              } else {
                setEdit(edit.map(() => false));
              }
            }
          }}
        >
          Submit
        </Button>
      )}
    </Grid>
  );

  return renderForm();
}

export default MyndForm;

/*
export default function Test() {
  const [values, setValues] = React.useState({
    doj: Math.floor(new Date('Thu Apr 4 2022 0:0:0 GMT+0530')),
    name: 'Ajay Mendez',
    favouriteFruit: 'orange',
  });
  const spec = {
    title: 'User',
    buttonColor: '#5fd38d',
    onSubmit: (val) => {
      setValues(val);
    },
    items: [
      {
        // 0
        title: 'Email Id of user',
        type: 'text',
        field: 'email',
        mandatory: true,
      },
      {
        // 1
        title: 'Name of user',
        type: 'text',
        field: 'name',
        mandatory: true,
      },
      {
        // 2
        title: 'Favourite fruit of user',
        type: 'autocomplete',
        params: ['apple', 'orange', 'sapota', 'grape'],
        field: 'favouriteFruit',
      },
      {
        // 3
        title: 'Age of user',
        type: 'number',
        field: 'userAge',
      },
      {
        // 4
        title: 'Date of joining',
        type: 'date',
        field: 'doj',
      },
      {
        // 5
        title: 'User feedback',
        type: 'rating',
        field: 'feedback',
      },
      {
        // 6
        title: 'User support category',
        params: ['web site', 'telephone', 'in person', 'whatsapp', 'other'],
        type: 'buttonGroup',
        field: 'supportCategory',
      },
    ],
  };

  const renderValues = () => (
    <Grid container direction="row">
      {Object.keys(values).map((v) => (
        <Grid item xs={12} key={v.field}>
          {`${v}: ${values[v]}`}
        </Grid>
      ))}
    </Grid>
  );

  return (
    <div>
      <MyndForm spec={spec} initValues={values} />
      {renderValues()}
    </div>
  );
}
*/
