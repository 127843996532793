/* eslint-disable no-console, react/jsx-props-no-spreading, react/prop-types,
   no-param-reassign, no-shadow, no-use-before-define */
import React from 'react';
import { Chart } from 'react-google-charts';
import ExcelJS from 'exceljs';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import * as Utils from '../utils';

export default function Won(props) {
  const { leads } = props;
  const byRegion = [['Region', 'Leads', 'Wins', 'Region', 'Revenue']]
    .concat(Utils.regions.map((x) => ([x, 0, 0, x, 0])));
  const max = { leads: 0, wins: 0 }; // To track max leads and wins
  // d is last April
  const d = new Date();
  if (d.getMonth() <= 2) {
    d.setFullYear(d.getFullYear() - 1);
  }
  d.setMonth(3);
  d.setDate(1);
  d.setHours(0);
  d.setMinutes(0);
  const da = Math.floor(d);

  leads.forEach((x) => {
    const e = byRegion.find((r) => r[0] === x.region);
    if (!e) {
      return;
    }
    e[1] += 1;
    if (e[1] > max.leads) {
      // eslint-disable-next-line
      max.leads = e[1];
    }
    if (x.state === 'won' && x.deployDate > da) {
      e[2] += 1;
      if (e[2] > max.wins) {
        // eslint-disable-next-line
        max.wins = e[2];
      }
      e[4] += (x.estimatedRate ? x.estimatedRate * 12 : 0);
    }
  });

  const downloadExcel = () => {
    let row = null;

    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Myndrix auto generator';
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 1,
        visibility: 'visible',
      },
    ];
    const sheet = workbook.addWorksheet('Revenue By Region', {
      headerFooter: {
        firstHeader: '',
        firstFooter: '',
      },
    });
    // Set Frozen View - ie. top 1 rows dont scroll
    sheet.views = [
      { state: 'frozen', xSplit: 0, ySplit: 1 },
    ];
    // Set column widths
    const cols = [
      { header: 'Region', key: 'region', width: 28 },
      { header: 'Leads', key: 'leads', width: 12 },
      { header: 'Wins', key: 'wins', width: 12 },
      { header: 'Conversion (%)', key: 'conversion', width: 12 },
      { header: 'Revenue (Lakhs)', key: 'revenue', width: 20 },
    ];

    sheet.columns = cols;
    // Set header styling
    const heading = sheet.getRow(1);
    heading.font = {
      name: 'Arial',
      family: 4,
      size: 11,
      color: { argb: '4d4d4d' },
      underline: false,
      bold: true,
    };
    heading.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    heading.height = 25;
    heading.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'AFE9C6' },
      };
    });
    // Fill data
    const totals = { leads: 0, wins: 0, revenue: 0 };
    byRegion.forEach((x, idx) => {
      if (idx === 0) {
        return;
      }
      row = sheet.getRow(idx + 1);
      row.values = [
        x[0],
        x[1],
        x[2],
        x[1] ? Utils.strToFloat(((x[2] * 100) / x[1]).toFixed(0)) : 0,
        Utils.strToFloat(x[4].toFixed(3)),
      ];
      totals.leads += x[1];
      totals.wins += x[2];
      totals.revenue += x[4];
    });

    row = sheet.getRow(byRegion.length + 1);
    row.values = [
      'Total',
      totals.leads,
      totals.wins,
      '',
      Utils.strToFloat(totals.revenue.toFixed(3)),
    ];
    row.font = {
      name: 'Arial',
      family: 4,
      size: 11,
      color: { argb: '4d4d4d' },
      underline: false,
      bold: true,
    };

    workbook.xlsx.writeBuffer().then((data) => {
      const today = new Date();
      const blob = new Blob(
        [data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      );
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `Revenue-by-region-${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const renderDownload = () => (
    <Button
      variant="contained"
      color="primary"
      size="small"
      style={{ backgroundColor: '#5fd38d', margin: 5 }}
      onClick={() => downloadExcel()}
    >
      Export
    </Button>
  );

  const options = {
    title: 'Revenue Won by Region (YTD)',
    hAxis: { title: 'Leads (YTD)', viewWindow: { min: -1, max: max.leads + 10 } },
    vAxis: { title: 'Wins (YTD)', viewWindow: { min: -1, max: max.wins + 2 } },
    bubble: { opacity: 0.6 },
    explorer: { actions: ['dragToPan', 'rightClickToReset'] },
  };
  return (
    <Grid
      container
      style={{
        background: 'white',
        borderColor: '#cccccc',
        borderWidth: 1,
        borderStyle: 'solid',
      }}
    >
      <Grid container direction="row" justifyContent="flex-end">
        {renderDownload()}
      </Grid>
      <Chart
        chartType="BubbleChart"
        width="100%"
        height="500px"
        data={byRegion}
        options={options}
      />
    </Grid>
  );
}
