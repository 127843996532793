import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { useSnackbar } from 'notistack';

import Switch from '@material-ui/core/Switch';
import Container from '@material-ui/core/Container';

import Teams from './Teams';
import MyndStepper from './MyndStepper';
import EditableText from './EditableText';
import * as Utils from '../utils';

function Users(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);

  const {roles} = props;

  useEffect(() => {
    const admins = [];
    const managers = [];
    const others = [];
    roles.forEach((x) => {
      // if (x.user === 'ajay@myndrix.com') return;
      if (x.isAdmin) {
        admins.push(x);
      } else if (x.isManager) {
        managers.push(x);
      } else {
        others.push(x);
      }
    });

    const d = admins.concat(managers).concat(others);
    setData(d);
  }, [roles]);

  const changeValue = (user, type, value) => window
    .firestore
    .collection('roleMap')
    .doc(user)
    .set({ [type]: value }, { merge: true })
    .catch((err) => {
      console.log('Permission set error: ', err);
      return null;
    });

  const renderTable = () => {
    const cols = [
      {
        title: 'Name',
        field: 'name',
        render: (rowData) => (
          <div>
            <EditableText
              text={rowData.name}
              width={150}
              onSave={(newName) => changeValue(rowData.email, 'name', newName)}
            />
            {rowData.email}
          </div>
        ),
      },
      {
        title: 'Is Administrator',
        field: 'isAdmin',
        type: 'boolean',
        render: (rowData) => (
          <Switch
            checked={rowData.isAdmin}
            color="secondary"
            size="small"
            onChange={(e) => changeValue(rowData.email, 'isAdmin', e.target.checked)}
          />
        ),
      },
      {
        title: 'Is Manager',
        field: 'isManager',
        type: 'boolean',
        render: (rowData) => (
          <Switch
            checked={rowData.isManager}
            color="primary"
            size="small"
            onChange={(e) => changeValue(rowData.email, 'isManager', e.target.checked)}
          />
        ),
      },
      {
        title: 'is Active',
        field: 'isRegular',
        type: 'boolean',
        render: (rowData) => (
          <Switch
            checked={rowData.isRegular}
            color="primary"
            size="small"
            onChange={(e) => {
              if (e.target.checked) {
                changeValue(rowData.email, 'isRegular', true);
              } else {
                changeValue(rowData.email, 'isRegular', false);
                changeValue(rowData.email, 'isManager', false);
                changeValue(rowData.email, 'isAdmin', false);
              }
            }}
          />
        ),
      },
      {
        title: 'Teams',
        field: 'teams',
        render: (rowData) => (
          <Teams
            user={rowData.email}
            teams={rowData.teams}
          />
        ),
      },
    ];

    const options = {
      pageSize: 20,
      headerStyle: {
        backgroundColor: '#afe9c6e0',
      },
      rowStyle: (rowData) => ({
        backgroundColor: rowData.isRegular ? 'white' : '#cccccc',
      }),
    };

    return (
      <MaterialTable
        title="Users & Roles"
        columns={cols}
        data={data}
        options={options}
        isLoading={false}
      />
    );
  };

  const renderStepper = () => {
    const spec = {
      title: 'User',
      buttonColor: '#5fd38d',
      onSubmit: (val) => (
        window.firestore.collection('roleMap').doc(val.email).get()
          .then((ret) => {
            if (ret.exists) {
              enqueueSnackbar(`User ${val.email} already exists!`, { variant: 'error' });
              return null;
            }
            const rec = {
              appUid: Utils.UUID(),
              isRegular: true,
              name: val.name,
              email: val.email,
              orgId: global.role.orgId,
            };
            return window.firestore.collection('roleMap').doc(val.email)
              .set(rec, { merge: true })
              .then(() => {
                enqueueSnackbar(`User ${val.name} added!`, { variant: 'success' });
              })
              .catch((err) => {
                enqueueSnackbar(err, { variant: 'error' });
              });
          })
      ),
      steps: [
        {
          // 0
          title: 'Email Id of user',
          type: 'text',
          field: 'email',
          nxtAction: (val) => {
            if (!val.email) return { error: true, message: 'Email ID is required' };
            return { error: false };
          },
        },
        {
          // 1
          title: 'Name of user',
          type: 'text',
          field: 'name',
          nxtAction: (val) => {
            if (!val.name) return { error: true, message: 'User name is required' };
            return { error: false };
          },
        },
      ],
    };

    return <MyndStepper spec={spec} />;
  };

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: 20 }}
    >
      {renderStepper()}
      {renderTable()}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  roles: state.firestore.roles,
  metaData: state.firestore.metaData,
});

export default connect(mapStateToProps)(Users);
